import React from 'react';
import PropTypes from 'prop-types';
import FormModal from '../fetch/FormModal';
import generalFailedPC from '../fetch/generalFailedPC';
import {Trans, t} from '@lingui/macro';
import EventForm from './EventForm';
import withDataHOC from '../dataProvider/withDataHOC';
import { GLOBAL_DATA } from '../../constants/globalData';
import { createFetchEventDetails } from '../../backend/apiCalls';
import { notification } from 'antd';

/**
 * @dusan
 */


class EventModal extends React.PureComponent {
    static propTypes = {
        wrapperClassName: PropTypes.string,
        eventId: PropTypes.number,
    };

    static defaultProps = {
        wrapperClassName: '',
    };

    constructor(props) {
        super(props);
        this.state = {
            eventObj: {},
            loading: false,
        };
    }

    fetchEvent = () => {
        const {eventId} = this.props;
        if(eventId == null)
        {
            this.setState({
                eventObj: {},
                loading: false,
            });
            return;
        }

        const fetchHandler = this.props[GLOBAL_DATA.FETCH_HANDLER];
        this.setState({loading: true});
        fetchHandler(
            createFetchEventDetails(),
            {id: eventId},
            (result) => {
                this.setState({
                    eventObj: result,
                    loading: false,
                });
            },
            () => {},
            (error) => {
                this.setState({
                    eventObj: {},
                    loading: false
                });

                notification['error']({ 
                    message: error.message,
                    duration: 10,
                });
            }
        )
    }

    render() {
        const {wrapperClassName, children} = this.props;
        const {eventObj} = this.state;
        return <FormModal
            className={wrapperClassName}
            openNode={children}
            values={{isEdit: true, eventObj: eventObj}}
            onFinishSuccessful={this.fetchEvent}
            title={<Trans>Upraviť event</Trans>}
            Form={EventForm}
            Response={null}
            onOpen={this.fetchEvent}
            Failed={generalFailedPC(t`Nepodarilo sa upraviť event.`)}
        />;
    }

}

export default withDataHOC([GLOBAL_DATA.FETCH_HANDLER])(EventModal);