import React from 'react';
import PropTypes from 'prop-types';

import Helmet from '../project/Helmet';
import update from 'immutability-helper';
import withDataHOC from '../dataProvider/withDataHOC';
import locationHOC from '../locationProvider/locationHOC';
import {GLOBAL_DATA} from '../../constants/globalData';
import {QUERY_PARAMS} from '../../constants/navigation';
import {navigateToParametrized, getQueryParam} from '../../lib/url';
import {AnalysisType} from '../../constants/propTypesDefinitions';
import {Trans, t} from '@lingui/macro';
import AnalysisParams from './AnalysisParams';
import { createFetchAnalysisGenerate } from '../../backend/apiCalls';
import MobilePageHeader from '../general/MobilePageHeader';
import AnalysisTable from './AnalysisTable';
import AnalysisPath from './AnalysisPath';
import { notification } from 'antd';

/**
 * @dusan
 */

class AnalysisPage extends React.PureComponent {
    static propTypes = {
        [GLOBAL_DATA.ANALYSIS_TYPES]: PropTypes.arrayOf(AnalysisType.isRequired).isRequired,
        [GLOBAL_DATA.FETCH_HANDLER]: PropTypes.func.isRequired,
        location: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            analysis: null,
            filter: null,
            orderBy: null,
            loading: false,
        };
    }

    componentDidMount() {
        const {location} = this.props;
        const fetchOnLoad = getQueryParam(location, QUERY_PARAMS.ANALYSIS_PREFETCH);
        if(fetchOnLoad)
            this.fetchResult();
    }

    getSelectedType = () => {
        const {[GLOBAL_DATA.ANALYSIS_TYPES]: types} = this.props;
        const params = this.getParamsObject();
        const type = params != null ? params.type : null;
        return types.find(t => t.name == type);
    }

    onParamsChange = (newParams) => {
        const {location} = this.props;
        const paramString = JSON.stringify(newParams);
        navigateToParametrized(location, null, {[QUERY_PARAMS.ANALYSIS_PARAMS]: paramString});

        if(newParams.type == null) {
            this.setState({
                analysis: null, 
                filter: null, 
                orderBy: null, 
                loading: false
            });
        }
    };
    
    onPathChange = (newFilter) => {
        // decrease the key cols only to those contained in newFilter
        const params = this.getParamsObject();
        const newKeyCols = params.key_cols != null && newFilter != null ? params.key_cols.filter(col => newFilter[col] !== undefined) : [];
        const newParams = update(params, {key_cols: {$set: newKeyCols}});

        this.fetchResult(newFilter, newParams);
        this.onParamsChange(newParams);
    };

    onExpand = (newFilter, newKeyCol) => {
        const params = this.getParamsObject();
        const newParams = newKeyCol != null ? update(params, {key_cols: {$push: [newKeyCol]}}) : params;
        
        this.fetchResult(newFilter, newParams);
        this.onParamsChange(newParams);
    }

    onCacheRefresh = () => {
        const params = this.getParamsObject();
        const newParams = update(params, {no_cache: {$set: 1}});
        
        this.fetchResult(null, newParams);
    }

    getParamsObject = () => {
        const {location} = this.props;
        const paramString = getQueryParam(location, QUERY_PARAMS.ANALYSIS_PARAMS);
        if(paramString != null)
            return JSON.parse(paramString);
        else
            return {};
    };

    onOrderChange = (orderBy) => {
        const {analysis} = this.state;
        
        if(analysis.rows == null)
            return; // nothing to do 

        if(orderBy == null || orderBy == '')
        {
            // get default ordering by the first key column
            const firstCol = analysis != null && analysis.key_cols != null && analysis.key_cols.length > 0 ? analysis.key_cols[0] : null;
            if(firstCol == null)
                return;

            orderBy = firstCol.col + ' asc';
        }
        
        const colDir = orderBy.split(' ');
        const colTim = colDir[0].split('@');
        const orderCol = colTim[0];
        const orderAt = colTim.length > 1 ? colTim[1] : null;
        const orderSign = colDir.length > 1 && colDir[1] == 'desc' ? (-1) : 1;

        if(orderAt != null)
        {
            // sample ordering
            const sampleNo = analysis.samples != null ? analysis.samples.findIndex(s => s.to == orderAt) : -1;
            if(sampleNo < 0)
                return;

            var compareFcn = (rowA, rowB) => {
                const sa = rowA.data != null ? rowA.data[sampleNo] : null;
                const sb = rowB.data != null ? rowB.data[sampleNo] : null;
                const a = parseFloat(sa != null ? sa[orderCol] : null);
                const b = parseFloat(sb != null ? sb[orderCol] : null);

                if(Number.isNaN(a))
                    return (Number.isNaN(b) ? 0 : -orderSign);

                if(Number.isNaN(b))
                    return orderSign;

                return orderSign * (a - b);
            };
        }
        else
        {
            // key ordering
            var compareFcn = (rowA, rowB) => {
                const ka = rowA.key != null ? rowA.key[orderCol] : null;
                const kb = rowB.key != null ? rowB.key[orderCol] : null;
                const a = ka != null ? ka.label : null;
                const b = kb != null ? kb.label : null;

                if(a == null || b == null)
                    return 0;

                return orderSign * a.localeCompare(b);
            }
        }

        const newRows = analysis.rows.sort(compareFcn);

        this.setState({
            orderBy: orderBy,
            analysis: update(analysis, {rows: {$set: newRows}}),
        });

    };

    fetchResult = (newFilter, newParams) => {
        const {filter} = this.state;
        const fetchHandler = this.props[GLOBAL_DATA.FETCH_HANDLER];
        const filterChecked = newFilter != null ? newFilter : (filter != null ? filter : {});
        const params = newParams != null ? newParams : this.getParamsObject();
        const paramsWithFilter = update(params, {filter: {$set: filterChecked}});

        this.setState({loading: true});
        fetchHandler(
            createFetchAnalysisGenerate(),
            paramsWithFilter,
            (result) => {
                this.setState({
                    analysis: result,
                    loading: false,
                    filter: filterChecked
                });
            },
            null,
            (error) => {
                notification['error']({ 
                    message: error.message,
                    duration: 10,
                });
            }
        )
    };

    render() {
        const {analysis, loading, orderBy} = this.state;
        return <React.Fragment>
            <Helmet
                title={t`Analýzy`}
            />
            <div className="full-size-height d-flex flex-column overflow-y-auto">
                <MobilePageHeader title={<Trans>Analýza</Trans>}/>
                <AnalysisParams
                    params={this.getParamsObject()}
                    onChangeParams={this.onParamsChange}
                    onRefresh={() => { this.fetchResult() }}
                    loading={loading}
                />
                <AnalysisPath
                    analysis={analysis}
                    onChange={this.onPathChange}
                    onCacheRefresh={this.onCacheRefresh}
                />
                <AnalysisTable
                    analysis={analysis}
                    analysisType={this.getSelectedType()}
                    orderBy={orderBy}
                    onOrderChange={this.onOrderChange}
                    onExpand={this.onExpand}
                    loading={loading}
                />
            </div>
        </React.Fragment>;
    }

}

export default locationHOC(withDataHOC([GLOBAL_DATA.FETCH_HANDLER, GLOBAL_DATA.ANALYSIS_TYPES])(AnalysisPage));