import React from 'react';
import PropTypes from 'prop-types';
import { SESSION_ATTRIBUTES } from '../sessionProvider/SessionProvider';
import withSessionHOC from '../sessionProvider/withSessionHOC';

/**
 * @fero
 */

class MobilePageHeader extends React.PureComponent {
    static propTypes = {
        title: PropTypes.node.isRequired,
        [SESSION_ATTRIBUTES.IS_MOBILE]: PropTypes.bool,
    };

    render() {
        const {title, [SESSION_ATTRIBUTES.IS_MOBILE]: isMobile} = this.props;
        return isMobile ? <h2 className="text-center">{title}</h2> : null;
    }

}

export default withSessionHOC([SESSION_ATTRIBUTES.IS_MOBILE])(MobilePageHeader);