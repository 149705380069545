import React from 'react';
import {Trans, t} from '@lingui/macro';
import PropTypes from 'prop-types';
import {AnalysisResult, AnalysisType} from '../../constants/propTypesDefinitions';
import TableHeaderOrderer from '../general/TableHeaderOrderer';
import { stopPropagation } from '../../lib/interaction';
import {sysDecimalSeparator} from '../../lib/number';
import { Select } from 'antd';
import FilterSelect from '../general/Select';
import ObjectLink from '../project/ObjectLink';
import update from 'immutability-helper';

/**
 * @dusan
 */

class AnalysisTable extends React.PureComponent {
    static propTypes = {
        analysis: AnalysisResult,
        analysisType: AnalysisType,
        orderBy: PropTypes.string,
        onOrderChange: PropTypes.func.isRequired,
        onExpand: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            filter: {}
        };
    }

    onChangeFilter = (col, val) => {
        const {filter} = this.state;
        const valChecked = val != "NULL" ? val : null;
        const newFilter = update(filter, val != null ? {[col]: {$set: valChecked}} : {$unset: [col]});
        this.setState({filter: newFilter});
    };

    exportCsv = () => {
        const {analysis} = this.props;
        const {filter} = this.state;

        if(analysis == null || analysis.rows == null || analysis.samples == null || analysis.data_cols == null)
            return;

        const keyHeaders = analysis.key_cols != null ? 
            analysis.key_cols.map(colDef => "\"" + colDef.caption + "\"") : [];

        const dataHeaders = analysis.samples.reduce((cells, sample) => {
                const heads = analysis.data_cols.map(colDef => "\"" + (sample.from != null ? colDef.caption + ' od ' + sample.from + ' do ' : '') + sample.to + "\"");
                return [...cells, ...heads];
            }, 
            []
        );

        const replaceDecimal = (sysDecimalSeparator() != '.');

        const rowsFiltered = analysis.rows.filter((row) => {
            for (const [keyCol, keyItem] of Object.entries(row.key))
            {
                if(filter[keyCol] !== undefined && filter[keyCol] != keyItem.val)
                    return false;

            }
            return true;
        });

        const rows = rowsFiltered.map((row) => {
            const keyVals = analysis.key_cols != null ? 
                analysis.key_cols.map((colDef) => {
                    const rowKey = row.key != null ? row.key[colDef.col] : null;
                    return rowKey != null ? "\"" + rowKey.label.toString().replaceAll('"', '""') + "\"" : '';
                }) : [];

            const dataVals = analysis.samples.reduce((cells, sample, sampleId) => {
                const rowData = row.data != null ? row.data[sampleId] : null;
                const sampleVals = analysis.data_cols.map(colDef => {
                    const val = rowData != null ? rowData[colDef.col] : null;
                    return val != null ? (replaceDecimal ? val.toString().replace('.', ',') : val) : '';
                });

                return [...cells, ...sampleVals];
            }, []);

            return [...keyVals, ...dataVals].join(';');
        });

        const csvString = [...keyHeaders, ...dataHeaders].join(";") + "\r\n" + rows.join("\r\n");
        const csvData = new Blob(["\ufeff", csvString], { type: 'text/csv' });

        var a = document.getElementById('table-download-link');
        if(a == null) {
            a = document.createElement('a');
            a.id = 'table-download-link';
            a.target = '_blank';
            a.style = "visibility:hidden;";
            a.download = 'export.csv';
            document.body.appendChild(a);
        }

        a.setAttribute('href', URL.createObjectURL(csvData));
        a.click();
    }

    handleKeyDown = (ev) => { 
        // check for Ctrl + E or Action + E (Mac)     
        if ((ev.ctrlKey || ev.metaKey) && (ev.key == 'x' || ev.key == 'X')) {
            stopPropagation(ev);
            this.exportCsv();
        }
    }

    render() {
        const {analysis, analysisType, orderBy, onOrderChange, onExpand} = this.props;
        const {filter} = this.state;

        if(analysis == null)
            return null;

        const unusedKeyCols = analysisType != null ? analysisType.key_cols.filter(colDef => {
            const found = analysis.key_cols != null ? analysis.key_cols.find(c => c.col == colDef.col) : null;
            return (found == null); // not found
        }) : [];

        return <div className="p-3 full-size-width">
            <table className="shop-table shop-table-auto" tabIndex={1} onKeyDown={this.handleKeyDown}>
                <thead>
                    <tr>
                        {analysis.key_cols != null ? analysis.key_cols.map((colDef, idx) => {
                            const optionEntries = colDef.options != null ? Object.entries(colDef.options) : [];
                            const filterOptions = optionEntries.map(([val, label]) => { return {value: (val || "NULL"), label: label}; });
                            const filterVal = filter[colDef.col];
                            return <th key={idx} rowSpan={2} className="text-center" style={{overflow: 'visible'}}>
                                <TableHeaderOrderer
                                    id={colDef.col}
                                    orderBy={orderBy}
                                    onChange={onOrderChange}
                                >
                                    {colDef.caption}
                                </TableHeaderOrderer>
                                <FilterSelect
                                    className="pt-2 full-size-width text-left"
                                    value={filterVal}
                                    onChange={(newVal) => this.onChangeFilter(colDef.col, newVal)}
                                    options={filterOptions}
                                    allowClear={true}
                                />
                            </th>;
                        }) : null}
                        {analysis.samples != null ? analysis.samples.map((sample, idx) => {
                            return <th key={idx} colSpan={analysis.data_cols != null ? analysis.data_cols.length : 1} className="text-center">
                                    { sample.from != null ? 
                                        t`od` + ' ' + sample.from + ' ' + t`do` + ' ' + sample.to
                                        : sample.to
                                    }
                                </th>;
                        }) : null}
                        <th rowSpan={2}></th>
                    </tr>
                    <tr>
                        {analysis.samples != null ? analysis.samples.map((sample, idx) => {
                            return <React.Fragment key={idx}>
                                { analysis.data_cols != null ? analysis.data_cols.map((colDef, colIdx) => {
                                        return <th key={colIdx} className="text-center">
                                            <TableHeaderOrderer
                                                id={colDef.col + '@' + sample.to}
                                                orderBy={orderBy}
                                                onChange={onOrderChange}
                                                descFirst={true}
                                            >
                                                {colDef.caption}
                                            </TableHeaderOrderer>
                                        </th>;
                                    }) : null
                                }
                            </React.Fragment>;
                        }) : null}
                    </tr>
                </thead>
                <tbody>
                { analysis.rows != null ? analysis.rows.map((row, rowId) => {
                    let keyObj = {};
                    if(row.key != null)
                    {
                        for (const [keyCol, keyItem] of Object.entries(row.key))
                        {
                            if(filter[keyCol] !== undefined && filter[keyCol] != keyItem.val)
                                return null;

                            keyObj[keyCol] = keyItem.val;
                        }
                    }
                        
                    return <tr key={rowId}>
                        {analysis.key_cols != null ? analysis.key_cols.map((colDef, colIdx) => {
                            const keyVal = row.key != null && row.key[colDef.col] != null ? row.key[colDef.col].val : null;
                            const keyLabel = row.key != null && row.key[colDef.col] != null ? row.key[colDef.col].label : null;
                            return <td key={colIdx}>
                                <ObjectLink
                                    objClass={colDef.class}
                                    objId={keyVal}
                                    objLabel={keyLabel}
                                />
                            </td>;
                        }) : null}
                        {analysis.samples != null ? analysis.samples.map((sample, idx) => {
                            const sampleData = row.data != null && row.data[idx] != null ? row.data[idx] : {};
                            return <React.Fragment key={idx}>
                                {analysis.data_cols != null ? analysis.data_cols.map((colDef, colIdx) => {
                                    return <td key={colIdx} className="text-right">
                                        {sampleData[colDef.col]}
                                    </td>;
                                }) : null}
                            </React.Fragment>;
                        }) : null}
                        <td className="text-center">
                            <Select
                                value={undefined}
                                onChange={(newKeyCol) => {onExpand(keyObj, newKeyCol)}}
                                placeholder={<Trans>Ďalšia úroveň</Trans>}
                                allowClear={true}
                                defaultActiveFirstOption={false}
                                disabled={unusedKeyCols.length == 0}
                                className="p-1 analysis-key-select"
                                size="small"
                            >
                                {unusedKeyCols.map((colDef, idx) => {
                                    return <Select.Option key={idx} value={colDef.col}>{colDef.caption}</Select.Option>
                                })}
                            </Select>
                        </td>
                    </tr>;
                }) : null}
                </tbody>
            </table>
        </div>;
    }
}

export default AnalysisTable;