import React from 'react';
import PropTypes from 'prop-types';
import {Button} from 'antd';
import {Trans, t} from '@lingui/macro';
import { QUERY_PARAMS, ROUTES, STOCK_ROUTES } from '../../constants/navigation';
import Link from '../navigation/Link';
import { STOCK_ROOT } from '../../constants/apiEndpoints';
import { addQueryParamsToUrl } from '../../lib/url';
import EventEdit from '../events/EventEdit';
import EventModal from '../events/EventModal';

/**
 * @dusan
 */

class ObjectLink extends React.PureComponent {
    static propTypes = {
        objClass: PropTypes.string,
        objId: PropTypes.number,
        objLabel: PropTypes.string,
        customerId: PropTypes.number,
    };

    render() {
        const {objClass, objId, objLabel, customerId} = this.props;

        if(objClass == null || objId == null || objLabel == null)
            return objLabel;

        let path = null;
        let queryParam = null;
        let isExternal = false;

        switch(objClass)
        {
            case 'product':
                path = ROUTES.PRODUCT_DETAILS;
                queryParam = QUERY_PARAMS.ID_PRODUCT;
                break;

            case 'order':
                path = ROUTES.ORDER_DETAILS;
                queryParam = QUERY_PARAMS.ID_ORDER;
                break;

            case 'complaint':
                path = STOCK_ROOT + STOCK_ROUTES.COMPLAINT_DETAILS;
                queryParam = 'id';
                isExternal = true;
                break;

            case 'request':
                path = ROUTES.REQUEST_DETAILS;
                queryParam = QUERY_PARAMS.ID_REQUEST;
                break;

            case 'quotation':
                path = ROUTES.QUOTATION_DETAILS;
                queryParam = QUERY_PARAMS.ID_QUOTATION;
                break;

            case 'transfer':
                path = STOCK_ROOT + STOCK_ROUTES.DISPATCH_DETAILS;
                queryParam = 'id';
                isExternal = true;
                break;

            case 'invoice':
                path = STOCK_ROOT + STOCK_ROUTES.INVOICE_DETAILS;
                queryParam = 'id';
                isExternal = true;
                break;

            case 'user':
                path = ROUTES.CUSTOMERS_USER_DETAILS;
                queryParam = QUERY_PARAMS.ID_USER;
                break;

            case 'customer':
                path = ROUTES.CUSTOMER_DETAILS;
                queryParam = QUERY_PARAMS.ID_PAGE_CUSTOMER;
                break;

            case 'inventory':
                path = STOCK_ROOT + STOCK_ROUTES.INVENTORY_DETAILS;
                queryParam = 'id';
                isExternal = true;
                break;

            case 'shipment':
                path = STOCK_ROOT + STOCK_ROUTES.SHIPMENT_DETAILS;
                queryParam = 'id';
                isExternal = true;
                break;

            case 'event':
                return <EventModal eventId={objId}>
                    {objLabel}
                </EventModal>;
                


            // TODO

            default:
                return objLabel;
        }

        if(isExternal)
        {
            return <a target="_blank" href={addQueryParamsToUrl(path, {[queryParam]: objId})} className="text-dark">
                {objLabel}
            </a>;
        }
        else
        {
            let queryParams = {[queryParam]: objId};
            if(customerId != null)
                queryParams[QUERY_PARAMS.ID_CUSTOMER] = customerId;

            return <Link
                className="text-dark"
                to={path}
                queryParams={queryParams}
                title={<Trans>Zobraziť detail</Trans>}
            >
                {objLabel}
            </Link>;
        }
    }
}

export default ObjectLink;
