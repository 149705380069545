import React from 'react';
import PropTypes from 'prop-types';
import {Trans, t} from '@lingui/macro';
import {AnalysisResult} from '../../constants/propTypesDefinitions';
import { Breadcrumb } from 'antd';
import update from 'immutability-helper';
import DateTime from '../general/DateTime';


/**
 * @dusan
 */

class AnalysisPath extends React.PureComponent {
    static propTypes = {
        analysis: AnalysisResult,
        onChange: PropTypes.func.isRequired,
        onCacheRefresh: PropTypes.func.isRequired,
    };


    render() {
        const {analysis, onChange, onCacheRefresh} = this.props;
        
        if(analysis == null)
            return null;

        return <div className="d-flex flex-wrap full-size-width px-3">
            <Breadcrumb>
                <Breadcrumb.Item key="root" onClick={() => { onChange({}); }}>
                    <span className="font-weight-bold">{analysis.label}</span>
                </Breadcrumb.Item>
                {analysis.filter != null && analysis.key_cols != null ? 
                    analysis.key_cols.map((keyColDef, colIdx) => {
                        if(analysis.filter[keyColDef.col] === undefined || Array.isArray(analysis.filter[keyColDef.col]))
                            return null;

                        const filterObj = analysis.key_cols.reduce((obj, colDef, idx) => {
                            if(idx > colIdx || analysis.filter[colDef.col] === undefined)
                                return obj;

                            return update(obj, {[colDef.col]: {$set: analysis.filter[colDef.col]}});
                            
                        }, {});

                        const firstRow = analysis.rows != null ? analysis.rows[0] : null;
                        const firstRowKey = firstRow != null && firstRow.key != null ? firstRow.key[keyColDef.col] : null;
                    
                        return <Breadcrumb.Item key={colIdx} onClick={() => { onChange(filterObj); }}>
                            <span className="font-weight-bold">
                                {keyColDef.caption + ' = ' + (firstRowKey != null ? firstRowKey.label : '???')}
                            </span>
                        </Breadcrumb.Item>;
                    }) : null
                }
            </Breadcrumb>
            <div className="d-flex flex-grow-1 justify-content-end">
                <Trans>údaje platné k:</Trans>
                {' '}
                <DateTime timeString={analysis.timestamp}/>
                <div className="ml-2 link-button" onClick={onCacheRefresh}><Trans>Aktualizovať</Trans></div>
            </div>
        </div>;
    }

}

export default AnalysisPath;